import React, {ReactElement} from 'react'
import {FCC} from ".";

declare const incompatible: unique symbol
// eslint-disable-next-line
export type incompatible = typeof incompatible

export const type: unique symbol = Symbol()
export type Typed<T extends symbol> = { [type]: T }
export type ElementOf<T extends any[]> = T[number]

export type Inherited = { component: FCC }

export function scope(...states: Inherited[]): FCC {
  return ({children}) => states.reduceRight((c: ReactElement | null, s: Inherited) =>
    <s.component>{c}</s.component>, <>{children}</ >)
}

export function iden<T>() {
  return (_: T) => _
}

import {ElementOf, incompatible, Inherited, type, Typed} from "./typed";
import _ from 'lodash';
import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState, useTransition
} from "react";
import {tuple} from "./remote";
import {useMounted} from "./components";
import {useEventCallback} from "@mui/material";

export type KeyedSubject<K, V> = ((k: K) => [V, (_: V) => void]) &
  Inherited & {
  store: React.Context<((k: K) => Subject<V>) | undefined>
  useFindAll(): () => [K, Subject<V>][]
}

export type registrar<T> = (x: T) => () => void
export type Observable<T> = { readonly didSet: registrar<(_: T) => void> } & Typed<typeof subjectType>
export type StatefulObservable<T> = Observable<T> & {
  readonly value: T
}
export type Subject<T> = Omit<StatefulObservable<T>, 'value'> & {
  value: T
  readonly willGet: registrar<(_: T) => void>
} & Typed<typeof subjectType>

export const subjectType: unique symbol = Symbol()

export interface pipe<T> {
  (): [Observable<T>, (_: T) => void]
}

export function register<T>(registrar: { field: T[] }, element: T): () => void {
  registrar.field = [...registrar.field, element]
  return () => registrar.field = _.without(registrar.field, element)
}

export function registerSubject<T>(subject: Subject<T[]>, element: T) {
  return register({
    get field() {
      return subject.value
    },
    set field(v) {
      setTree(subject, v)
    }
  }, element)
}

// eslint-disable-next-line
export function pipe<T>(): [Observable<T>, (_: T) => void] {
  let didSetObservers = {
    field: [] as ((_: T) => void)[]
  }
  return [
    {
      [type]: subjectType,
      didSet(f: ElementOf<typeof didSetObservers.field>) {
        return register(didSetObservers, f)
      },
    },
    (t) => {
      for (const o of didSetObservers.field) o(t)
    },
  ]
}

export function subjectDelegated<T>(get: () => T, set: (v: T) => void): Subject<T> {
  let willGetObservers: ((_: T) => void)[] = []
  let didSetObservers: typeof willGetObservers = []
  return {
    [type]: subjectType,
    get value(): T {
      const v = get()
      for (const o of willGetObservers) o(v)
      return v
    },
    set value(t: T) {
      set(t)
      for (const o of didSetObservers) o(t)
    },
    willGet(f: ElementOf<typeof willGetObservers>) {
      willGetObservers.push(f)
      return () => (willGetObservers = _.without(willGetObservers, f))
    },
    didSet(f: ElementOf<typeof didSetObservers>) {
      didSetObservers.push(f)
      return () => (didSetObservers = _.without(didSetObservers, f))
    },
  }
}

export function subject<T>(init: T): Subject<T> {
  let value = init
  return subjectDelegated(
    () => value,
    (v) => (value = v),
  )
}

export function statefulObservableDelegated<T>(get: () => T, didSet: (v: (_: T) => void) => () => void): StatefulObservable<T> {
  return {
    [type]: subjectType,
    get value(): T {
      return get()
    },
    didSet
  }
}

export function useObservable<T>(o: Observable<T>, f: (_: T) => (() => void) | void, dependencies: any[] = []) {
  useEffect(() => {
    let prev: (() => void) | void
    const disposable = o.didSet(v => {
      prev?.()
      prev = f(v)
    })
    return () => {
      prev?.()
      disposable()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [f, o, ...dependencies])
}

export function useStatefulObservable<T>(o: StatefulObservable<T>): [T] {
  const [forceRender,] = useForceRender()
  const stateRef = useRef<T | undefined>(o?.value)
  useEffect(() => {
    setState(o?.value)
    return o?.didSet(setState)

    function setState(s: T | undefined) {
      if (stateRef.current !== s) {
        stateRef.current = s
        forceRender()
      }
    }
  }, [o, stateRef, forceRender])
  return [o?.value]
}

export function useEagerEffect(f: () => (() => void) | undefined, deps: any[]) {
  const depsRef = useRef<any[]>()
  const disposableRef = useRef<() => void>()
  if (!shallowArrayEq(depsRef.current, deps)) {
    disposableRef.current?.()
    depsRef.current = deps;
    disposableRef.current = f()
  }
  useEffect(() => () => {
    depsRef.current = undefined
    disposableRef.current?.()
    disposableRef.current = undefined
  }, [disposableRef])
}

export function shallowArrayEq(a: any[] | undefined, b: any[] | undefined): boolean {
  if (!a && !b) return true
  if (!a || !b) return false
  if (a?.length !== b?.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export function useSubject<T>(subject: Subject<T>, options?: any): [T, (_: T) => void]
export function useSubject<T>(subject: Subject<T> | undefined, options?: any): [T | undefined, ((_: T) => void) | undefined]
export function useSubject<T>(subject: Subject<T> | undefined): [T | undefined, ((_: T) => void) | undefined] {
  const [forceRender] = useForceRender()
  const stateRef = useRef<T | undefined>(subject?.value)
  const set: ((_: T) => void) | undefined = useMemo(() => {
    if (subject) return (v) => setTree(subject, v)
  }, [subject])
  let value = subject?.value
  // useEffect might be skipped due to Suspense
  useEffect(() => {
    setState(subject?.value)
    return subject?.didSet(setState)

    function setState(s: T | undefined) {
      if (stateRef.current !== s) {
        stateRef.current = s
        forceRender()
      }
    }
  }, [subject, stateRef, forceRender])
  return [value, set]
}

export const ForceRender = createContext<() => (c: () => void) => void>(() => c => c())

export const ForceRenderTransition: FC<PropsWithChildren> = ({children}) =>
  <ForceRender.Provider value={function Use() {
    return useTransition()[1]
  }}>{children}</ForceRender.Provider>

export function useForceRender(): [() => void, number] {
  const [mounted] = useMounted()
  const setState = useContext(ForceRender)()
  const [token, setToken] = useState(0)
  const tokenRef = useRef(token)
  tokenRef.current = token
  const forceRender = useEventCallback(() => {
    if (mounted.value) {
      setState(() => {
        setToken(tokenRef.current + 1)
      })
    }
  })
  return [forceRender, token]
}

type StatefulObservableFlow<A, B> = (_: StatefulObservable<A>) => StatefulObservable<B>

export function useDispose(deps: any[] = []): (_: () => void) => void {
  const disposables = useRef<(() => void)[]>([])
  useEffect(() => () => {
    _(disposables.current).forEach(c => c())
    disposables.current = []
    // eslint-disable-next-line
  }, [disposables, ...deps])
  return useCallback(c => disposables.current.push(c), [disposables])
}

export const StatefulObservables = {
  switchMapOp,
  switchMap,
  map: <A, B>(map: (a: A) => B): StatefulObservableFlow<A, B> => a => statefulObservableDelegated(() => map(a.value), next => a.didSet(v => next(map(v)))),
  combineLatestWith: <A, B>(b: StatefulObservable<B>): StatefulObservableFlow<A, [A, B]> => a => combineLatest(tuple(a, b)),
  lazy,
  combineLatest,
}

function switchMapOp<A, B>(dispose: (_: () => void) => void, f: (a: A, emit: (_: B) => void, onCancel: (_: () => void) => void) => B,): StatefulObservableFlow<A, B> {
  return o => lazy(() => {
    const downStream: (() => void)[] = []
    const state: Subject<B> = subject(f(o.value, v => state.value = v, c => downStream.push(c)))
    dispose(o.didSet(a => {
      cancelDownStream()
      state.value = f(a, v => state.value = v, c => downStream.push(c))
    }))
    dispose(cancelDownStream)
    return state

    function cancelDownStream() {
      _(downStream).splice(0, downStream.length).forEach(c => c())
    }
  })
}

function switchMap<A, B>(dispose: (_: () => void) => void, f: (a: A) => StatefulObservable<B>): StatefulObservableFlow<A, B> {
  return switchMapOp(dispose, (a, emit, onCancel) => {
    const s = f(a)
    onCancel(s.didSet(emit))
    return s.value
  })
}

function lazy<A, >(f: () => StatefulObservable<A>): StatefulObservable<A> {
  const fuse = _.once(f)
  return statefulObservableDelegated(() => fuse().value, n => fuse().didSet(n))
}

function combineLatest<t extends StatefulObservable<any>[]>(s: t): StatefulObservable<statefulObservableValues<t>>
function combineLatest<t extends { [_: string]: StatefulObservable<any> }, >(s: t): StatefulObservable<statefulObservableValues<t>>
function combineLatest(
  s: any): StatefulObservable<any> {
  return statefulObservableDelegated(() => combineStatefulObservables(s), n => {
    const disposables: (() => void)[] = _(s as object).chain().values().map((ss: StatefulObservable<any>): () => void => ss.didSet(x => n(combineStatefulObservables(s)))).value()
    return () => _(disposables).splice(0, disposables.length).forEach(c => c())
  })
}

type stateObservableArrayValues<t extends any[], acc extends any[] = []> =
  t extends [StatefulObservable<infer a>, ...infer tail]
    ? stateObservableArrayValues<tail, [...acc, a]>
    : acc
export type stateObservableObjectValues<t extends object> = {
  [p in keyof t]: t[p] extends StatefulObservable<infer a> ? a : incompatible
}

export type statefulObservableValues<t extends StatefulObservable<any>[] | { [_: string]: StatefulObservable<any> }> =
  t extends StatefulObservable<any>[]
    ? stateObservableArrayValues<t>
    : stateObservableObjectValues<t>

export function combineStatefulObservables<t extends StatefulObservable<any>[] | { [_: string]: StatefulObservable<any> }>(rs: t): statefulObservableValues<t>
export function combineStatefulObservables(rs: object): any {
  if (Array.isArray(rs)) {
    const data: any[] = []
    for (const r of rs) {
      data.push(r.value)
    }
    return data
  }
  const data: any = {}
  for (const [key, s] of _.toPairs(rs)) {
    data[key] = s.value
  }
  return data
}


type StatefulObservableOps<A, operations> = {
  thru: <B, >(pick: (_: operations) => StatefulObservableFlow<A, B>) => StatefulObservableOps<B, operations>
  use: () => [A],
  unwrap(): StatefulObservable<A>
}

export function ops<A, operations = typeof StatefulObservables>(o: StatefulObservable<A>, os: operations = StatefulObservables as unknown as operations): StatefulObservableOps<A, operations> {
  return {
    thru: <B, >(pick: (_: operations) => StatefulObservableFlow<A, B>): StatefulObservableOps<B, operations> => ops(pick(os)(o)),
    // eslint-disable-next-line react-hooks/rules-of-hooks
    use: () => useStatefulObservable(o),
    unwrap: () => o
  }
}

const parentKey: unique symbol = Symbol()
const childrenKey: unique symbol = Symbol()
export type SubjectTree<T> = Subject<T> & {
  [parentKey]?: [string, SubjectTree<any>]
  [childrenKey]?: { [P in keyof T]?: SubjectTree<T[P]> }
}

export function propagateUp<T>(s: SubjectTree<T>, v: T) {
  const parent = s[parentKey]
  if (!parent) return
  const [path, parentSubj] = parent
  const parentV = {...parentSubj.value, [path]: v}
  parentSubj.value = parentV
  propagateUp(parentSubj, parentV)
}

export function propagateDown<T>(s: SubjectTree<T>, v: T) {
  const children: SubjectTree<T>[typeof childrenKey] = s[childrenKey]
  if (!children) return
  for (const path of Object.keys(children)) {
    const childV = _.get(v, path)
    const childSubj = children[path as keyof typeof children]
    if (childSubj) {
      childSubj.value = childV
      propagateDown(childSubj, childV)
    }
  }
}

export function getChild<T, P extends keyof T & string>(s: SubjectTree<T>, p: P): SubjectTree<T[P]> {
  const children: NonNullable<typeof s[typeof childrenKey]> = s[childrenKey] ?? {}
  s[childrenKey] = children
  let child = children[p]
  if (!child) {
    child = children[p] = subject(_.get(s.value, p))
    child[parentKey] = [p, s]
  }
  return child
}

export function setTree<T>(s: SubjectTree<T>, v: T) {
  s.value = v
  propagateUp(s, v)
  propagateDown(s, v)
}

export function keyedSubjects<Q, K, V>(key: (_: Q) => K, defaultVal: () => (_: Q) => V): KeyedSubject<Q, V> {
  const store = createContext<((_: Q) => Subject<V>) | undefined>(undefined)
  const findAll = createContext<(() => [Q, Subject<V>][]) | undefined>(undefined)
  const Component: FC<PropsWithChildren<{}>> = ({children}) => {
    const kToQ = useMemo(() => new Map<K, Q>(), [])
    const storeRef = useMemo(() => subjects<K, V>(), [])
    const defaultValContext = defaultVal()
    const getter = useCallback((q: Q) => {
      const k = key(q)
      kToQ.set(k, q)
      return storeRef(k, () => defaultValContext(q))
    }, [defaultValContext, kToQ, storeRef])
    const findAllFunc: () => [Q, Subject<V>][] = useCallback(() => {
      const result: [Q, Subject<V>][] = []
      for (const [k, v] of storeRef.store.entries() || []) {
        const q = kToQ.get(k)
        if (q) result.push([q, v])
      }
      return result
    }, [kToQ, storeRef])

    return (
      <findAll.Provider value={findAllFunc}>
        <store.Provider value={getter}>{children}</store.Provider>
      </findAll.Provider>
    )
  }
  const useHook: (_: Q) => [V, (_: V) => void] = (q) => {
    const get = useContext(store)
    if (!get) throw new Error('keyed_subjects is not in the ancestry tree')
    return useSubject(get(q))
  }
  const useFindAll = () => {
    const f = useContext(findAll)
    if (!f) throw new Error(`keyed_subjects is not in the ancestry tree`)
    return f
  }

  return Object.assign(useHook, {component: Component, store, useFindAll})
}

export function subjects<K, V>(): ((k: K, defaultVal: () => V) => Subject<V>) & { store: Map<K, Subject<V>> } {
  const map = new Map<K, Subject<V>>()
  return Object.assign(
    (k: K, defaultVal: () => V) => {
      const oldV = map.get(k)
      if (oldV) return oldV
      const newV = subject(defaultVal())
      map.set(k, newV)
      return newV
    },
    {store: map},
  )
}

type StatefulObserverGroup =
  (StatefulObservable<any> | undefined)[]
  | { [_: string]: (StatefulObservable<any> | undefined) }
export type StatefulObserverValues<S extends object> = S extends [StatefulObservable<infer head> | undefined, ...infer tail]
  ? [head | undefined, ...StatefulObserverValues<tail>]
  : { [p in keyof S]: S[p] extends StatefulObservable<infer v> | undefined ? v | undefined : incompatible }

// eslint-disable-next-line
export function statefulObservableValues<S extends StatefulObserverGroup>(s: S): any {
  if (Array.isArray(s)) return s.map(s => s?.value)
  return _.mapValues(s, (s: StatefulObservable<any> | undefined) => s?.value)
}

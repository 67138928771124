import {setTree, StatefulObservable, subject} from "./subject";
import {FC, PropsWithChildren, useEffect, useMemo} from "react";

export function useMounted(): [StatefulObservable<boolean>] {
  const subj = useMemo(() => subject(true), [])
  useEffect(() => {
    setTree(subj, true)
    return () => setTree(subj, false)
  }, [subj])
  return [subj]
}

export type FCC<T = {}> = FC<PropsWithChildren<T>>

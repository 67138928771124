import React, { FC, Suspense } from 'react';
import './App.css';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Container from "@mui/material/Container";
import Moderator from "./moderation";
import { CircularProgress } from "@mui/material";
import { useLog, useQuery } from "./globalStates";
import { scope } from "./states";
import LogViewer from "./LogList";
import HomePage from './home';

const States = scope(useLog, useQuery)

function App() {
  return (
    <BrowserRouter>
      <States>
        <Router />
      </States>
    </BrowserRouter>
  );
}


const Layout: FC = () => <div style={{ height: '100vh' }}>
  <Suspense fallback={<Container><CircularProgress /></Container>}>
    <Outlet />
  </Suspense>
</div>

const Router: FC = () => <Routes>
  <Route path='/' element={<Layout />}>
    <Route index element={<HomePage />} />
    <Route path="query" element={<HomePage />} />
    <Route path="moderation" element={<Moderator />} />
    <Route path="log/:session_id" element={<LogViewer />} />
  </Route>
</Routes>


export default App;

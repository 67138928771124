import {createContext, FC, useCallback, useContext, useMemo, useTransition} from "react";
import {getChild, Render, setTree, Subject, subject, useSubject} from "./states";
import {Box, Button, List, ListItem, ListItemIcon, ListItemText, SxProps, TextField, Theme} from "@mui/material";
import ScheduleIcon from '@mui/icons-material/Schedule';
import ErrorIcon from '@mui/icons-material/Error';
import Container from '@mui/material/Container';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DoneIcon from '@mui/icons-material/Done';

import _ from "lodash";
import axios from "axios";


const FlagCats = ["hate", "hate/threatening", "self-harm", 'sexual', 'sexual/minors', 'violence', 'violence/graphic'] as const;
type FlagState = Partial<Record<(typeof FlagCats)[number], boolean>>
type ReqState = Record<'state', 'init' | 'requesting' | 'success'> | { state: 'failure', error: any }
type ModItem = {
  text: string,
  flags: FlagState,
  req: ReqState,
}
type Filter = Partial<FlagState & Record<ReqState['state'], boolean>>

async function moderation(text: string[]): Promise<FlagState[]> {
  const resp = await axios.post('https://api.openai.com/v1/moderations', {input: text}, {
    headers: {
      Authorization: `Bearer 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`
    }
  })
  // @ts-ignore
  return _(resp).chain().get('data.results').map('categories').value() || [];
}

const ModFilter = createContext(subject({} as Filter))

const Moderator: FC = () => {
  const text = useMemo(() => subject(''), [])
  const items = useMemo(() => subject<Subject<ModItem>[]>([]), [])
  const quering = useMemo(() => subject(false), [])
  const filter = useMemo(() => subject<Filter>({}), [])
  const [, startTransition] = useTransition()
  const addItems = useCallback(() => startTransition(() =>
    setTree(items, items.value.concat(
      ...text.value.split("\n")
        .map(s => s.trim())
        .filter(s => !!s && !items.value.some(ss => ss.value.text === s))
        .map(text => subject({
          text, req: _.identity<ReqState>({state: 'init'}), flags: _.identity<FlagState>({})
        }))
    ))), [text, items])
  const start = useCallback(async () => {
    if (quering.value) {
      setTree(quering, false)
      return
    }
    setTree(quering, true)
    try {
      for (let chunk of _(items.value).filter(i => i.value.req.state === 'init').chunk(10).value())
        await request(chunk)
      for (let chunk of _(items.value).filter(i => i.value.req.state === 'failure').chunk(10).value())
        await request(chunk)
    } finally {
      setTree(quering, false)
    }

    async function request(items: Subject<ModItem>[]) {
      try {
        for (let item of items)
          setTree(getChild(item, 'req'), {state: 'requesting'})
        for (let [item, flags] of _.zip(items, await moderation(items.map(i => i.value.text)))) {
          setTree(getChild(item!, 'flags'), flags)
          setTree(getChild(item!, 'req'), {state: 'success'})
        }
      } catch (error) {
        for (let item of items)
          setTree(getChild(item, 'req'), {state: 'failure', error})
      }
    }
  }, [items, quering])
  const download = useCallback(() => {
    const csv = `text,${FlagCats.join(',')}\n` +
      items.value.filter(i => i.value.req.state === 'success').map(i =>
        `${i.value.text},${FlagCats.map(c => !!i.value.flags[c]).join(',')}`
      ).join('\n')
    const el = document.createElement("a");
    const file = new Blob([csv], {type: 'text/plain'});
    el.href = URL.createObjectURL(file);
    el.download = "moderation.csv";
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }, [items])
  return <Box sx={{display: 'flex', flexDirection: 'row'}}>
    <Container sx={{flex: 1}}>
      <div>
        <Button onClick={addItems}>Add</Button></div>
      <TextField
        sx={{minHeight: 100, width: '100%'}}
        multiline onChange={e => setTree(text, e.target.value)}/>
    </Container>
    <Container sx={{flex: 1}}>
      <div style={{marginBottom: 1}}>
        <Button onClick={start} sx={{marginRight: 1}}>Query</Button>
        <Button onClick={download} sx={{marginRight: 1}}>Download</Button>
      </div>
      <div>{
        FlagCats.map(c => <Render key={c}>{function FilterButton() {
          const [selected, setSelected] = useSubject(getChild(filter, c))
          return <Button
            size='small'
            variant={selected ? 'contained' : 'outlined'}
            sx={{opacity: selected ? 1 : 0.5, whiteSpace: 'nowrap', marginRight: 1, marginBottom: 1}}
            onClick={() => setSelected(!selected)}>{c}</Button>
        }}</Render>)
      }
        <Render>{function FilterButton() {
          const [f] = useSubject(filter)
          const selected = FlagCats.every(s => !!f[s])
          return <Button
            size='small'
            variant={selected ? 'contained' : 'outlined'}
            sx={{opacity: selected ? 1 : 0.5, whiteSpace: 'nowrap', marginRight: 1, marginBottom: 1}}
            onClick={() => setTree(filter, selected ? {} : _.fromPairs(FlagCats.map(c => [c, true])))}>All</Button>
        }}</Render>
      </div>
      <ModFilter.Provider value={filter}>
        <ModItemList items={items} sx={{minHeight: 100, width: '100%'}}/>
      </ModFilter.Provider>
    </Container>
  </Box>
}

export const ModItemList: FC<{ items: Subject<Subject<ModItem>[]>, sx?: SxProps<Theme> }> = ({items, sx}) => {
  const [list] = useSubject(items)
  return <List sx={sx}>
    {list.map((item, index,) => <ListItem disableGutters key={item.value.text}
                                          sx={{paddingTop: 0, paddingBottom: 0, margin: 0}}><Render>{function Item() {
      const [text] = useSubject(getChild(item, 'text'))
      const [req] = useSubject(getChild(item, 'req'))
      switch (req.state) {
        case 'init':
          return <><ListItemIcon><ScheduleIcon/></ListItemIcon><ListItemText sx={{opacity: 0.8}} primary={text}/></>
        case 'requesting':
          return <><ListItemIcon><DownloadingIcon/></ListItemIcon><ListItemText sx={{opacity: 0.8}} primary={text}/></>
        case 'failure':
          return <><ListItemIcon><ErrorIcon sx={{color: 'red'}}/></ListItemIcon><ListItemText primary={text}
                                                                                              secondary={<span
                                                                                                style={{color: 'red'}}>{req.error?.toString()}</span>}/></>
        case 'success':
          return <Render>{function Success() {
            const [filter] = useSubject(useContext(ModFilter))
            const [flags] = useSubject(getChild(item, 'flags'))
            const visible = !FlagCats.some(c => filter[c]) || FlagCats.some(c => filter[c] && flags[c]);
            if (!visible) return <></>
            const safe = !FlagCats.map(f => !!flags[f]).reduce((l, r) => l || r, false);
            if (safe) {
              return <><ListItemIcon><DoneIcon sx={{color: 'green'}}/></ListItemIcon><ListItemText primary={text}/></>
            }
            return <><ListItemIcon><ErrorIcon sx={{color: 'red'}}/></ListItemIcon><ListItemText primary={text}
                                                                                                secondary={<>
                                                                                                  {FlagCats.map(c =>
                                                                                                    <span
                                                                                                      key={c}
                                                                                                      style={{
                                                                                                        userSelect: 'none',
                                                                                                        whiteSpace: 'nowrap',
                                                                                                        padding: 2,
                                                                                                        margin: 2,
                                                                                                        borderRadius: 2,
                                                                                                        borderWidth: 1,
                                                                                                        borderStyle: 'solid',
                                                                                                        color: flags[c] ? 'red' : 'gray',
                                                                                                        borderColor: flags[c] ? 'red' : 'gray',
                                                                                                      }}>{c}</span>)}
                                                                                                </>}
            /></>
          }}</Render>
      }
      return <></>
    }}</Render></ListItem>)}
  </List>
}

export default Moderator

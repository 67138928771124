import {loading, Remote, RemotePaged} from "./remote";
import {useEffect, useMemo, useRef, useState} from "react";
import {usePreload} from "./preload";
import {setTree, subject, Subject, useObservable} from "./subject";
import _ from 'lodash'

type cached<V> = { state: 'available', value: V } | { state: 'unavailable' }
export type cache<Q, V> = {
  read: (_: Q) => Promise<cached<V>>,
  write: (q: Q, v: V) => Promise<void>
}

export function transparentCache<Q, V>(cache: cache<Q, V>): (_: (_: Q) => Promise<V>) => (_: Q) => Promise<V> {
  return next => async (q) => {
    const cached = await cache.read(q)
    if (cached.state === "available") return cached.value
    const newValue = await next(q)
    await cache.write(q, newValue)
    return newValue
  }
}

export const cacheFlow = _.curry(function useCacheFlow<Q, V>(cache: cache<Q, V>, q: Q, s: Subject<Remote<V>>): Subject<Remote<V>> {
  const downStream: Subject<Remote<V>> = useMemo(() => subject(s.value), [s])
  const cacheState = useRef('unavailable' as cached<any>['state'])
  useEffect(() => () => {
    cacheState.current = 'unavailable'
  }, [cacheState])
  useObservable(s, (v) => {
    if (v.state === 'success') {
      cache.write(q, v.data).catch(console.error)
    }
    if (v.state === 'failure') {
      if (cacheState.current === 'unavailable') {
        setTree(downStream, v)
      }
    } else if (v.state !== 'loading') {
      setTree(downStream, v)
    }
  }, [downStream, cache, q, cacheState])
  useEffect(() => {
    let cancelled = false
    ;(async () => {
      const cached = await cache.read(q)
      if (cancelled) return
      if (cached.state === 'available' && (s.value.state === 'loading' || s.value.state === 'failure')) {
        setTree(s, {state: 'success', data: cached.value})
      }
    })().catch(console.error)
    return () => {
      cancelled = true
    }
  }, [cache, q, s])
  return downStream
})

export function useCacheThen<Q, V>(cache: cache<Q, V>, q: Q, value: Remote<V>): Remote<V>
export function useCacheThen<Q, V>(cache: cache<Q, V>, q: Q, value: RemotePaged<V>): RemotePaged<V>
export function useCacheThen<Q, V>(cache: cache<Q, V>, q: Q, value: RemotePaged<V>): RemotePaged<V> {
  const preload = usePreload()
  const [cached, setCached] = useState<cached<V>>({state: "unavailable"})
  const cachedRemote = useMemo<RemotePaged<V>>(() =>
      cached.state === 'available' ?
        {state: 'success', data: cached.value} :
        loading,
    [cached])
  useEffect(() => {
    let cancelled = false
    ;(async () => {
      if (value.state === 'success') {
        await cache.write(q, value.data)
        if (!cancelled)
          setCached({state: 'available', value: value.data})
        preload()
      } else if (cached.state === 'unavailable') {
        const cached = await cache.read(q)
        if (!cancelled)
          setCached(cached)
      }
    })()
    return () => {
      cancelled = true
    }
  }, [q, cache, cached.state, value, preload])
  if (value.state === 'success' || value.state === 'loadingMore') return value
  if (cachedRemote.state === 'success') return cachedRemote
  return value
}

type localStorageCache<T> = {
  createdAt: number,
  value: T,
}

function memoizedCache<K, Q, V>(key: (_: Q) => K, cache: cache<Q, V>): cache<Q, V> {
  const map = new Map<K, V>()
  return {
    read: async (q) => {
      const k = key(q)
      if (map.has(k)) return {state: 'available', value: map.get(k) as V}
      const upstream = await cache.read(q)
      if (upstream.state === 'available') {
        map.set(k, upstream.value)
      }
      return upstream
    },
    write: async (q, v) => {
      const k = key(q)
      map.set(k, v)
      await cache.write(q, v)
    }
  }
}

// eslint-disable-next-line
export function cached<Q, V>() {
  let c: cached<V> = {state: "unavailable"}
  return {
    read: async (q: Q) => {
      return c
    },
    write: async (q: Q, v: V) => {
      c = {state: 'available', value: v}
    }
  }
}

// eslint-disable-next-line
export function localStorageCache<Q, V>(
  key: (_: Q) => string,
  ttl: number): cache<Q, V> {
  const prefix = '2022022120/'
  return memoizedCache<string, Q, V>(key, {
    read: async (q) => {
      try {
        const k = prefix + key(q)
        const v = localStorage.getItem(k)
        if (!v) return {state: 'unavailable'}
        const obj: localStorageCache<V> = JSON.parse(v)
        if (Date.now() - obj.createdAt >= ttl) {
          return {state: 'unavailable'}
        }
        return {state: 'available', value: obj.value}
      } catch (e) {
        console.error(e)
        return {state: 'unavailable'}
      }
    },
    write: async (q, v) => {
      try {
        const k = prefix + key(q)
        const s = JSON.stringify({
          createdAt: Date.now(),
          value: v
        })
        localStorage.setItem(k, s)
      } catch (e) {
        console.error(e)
      }
    }
  })
}

import { AppBar, Box, Container, Drawer, IconButton, Pagination, Paper, TextField, Toolbar, Typography } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import React, { useState, useEffect, useMemo } from "react";
import { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from "./globalStates";
import { remoteValue } from "./states";
type CalendarsDateRangePickerProps = {
  range: DateRange<Dayjs>,
  onChange: (range: DateRange<Dayjs>) => void,
}

function CalendarsDateRangePicker(props: CalendarsDateRangePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <Typography sx={{ mt: 2, mb: 1, ml: 0.5 }}>日期范围</Typography>
        <DateRangePicker
          calendars={1}
          value={props.range}
          onChange={(newValue) => {
            props.onChange(newValue)
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <TextField {...startProps} size="small" />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} size="small" />
            </React.Fragment>
          )}
        />
      </div>
    </LocalizationProvider>
  );
}

type QueryFormDrawerProps = {
  onQuery: (param: QueryParameters) => void
}

type QueryParameters = {
  sessionId?: string,
  lessonId?: string,
  userId?: string,
  dateRange: DateRange<Dayjs>
}

type QueryFormDrawerState = QueryParameters & { isOpen: boolean, isLoading: boolean }

function QueryFormDrawer(props: QueryFormDrawerProps) {
  const [state, setState] = React.useState<QueryFormDrawerState>({
    isOpen: false,
    isLoading: false,
    dateRange: [null, null]
  });

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }
        setState({ ...state, isOpen: open });
      };

  const onQuery = () => {
    props.onQuery(state)
    setState({ ...state, isOpen: false })
  }

  const onDateRangePickerChange = (range: DateRange<Dayjs>) => {
    setState({ ...state, dateRange: range })
  }

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    let target = event.target
    setState({ ...state, [target.id]: target.value })
  }

  return (
    <div>
      <React.Fragment >
        <IconButton
          size="large"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <SearchIcon />
        </IconButton>
        <Drawer
          anchor='right'
          open={state.isOpen}
          onClose={toggleDrawer(false)}
        >
          <Box sx={{ margin: "20px", width: "300px" }}>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>填写一个或多个条件</Typography>
            <TextField margin="normal" size="small" fullWidth label="Session ID" id="sessionId" value={state.sessionId} onChange={onTextFieldChange} />
            <TextField margin="normal" size="small" fullWidth label="Lesson ID" id="lessonId" value={state.lessonId} onChange={onTextFieldChange} />
            <TextField margin="normal" size="small" fullWidth label="User ID (支持多个，用英文逗号分隔)" id="userId" value={state.userId} onChange={onTextFieldChange} />
            <CalendarsDateRangePicker onChange={onDateRangePickerChange} range={state.dateRange} />
            <LoadingButton
              // loading={state.isLoading}
              loadingPosition="center"
              onClick={onQuery}
              sx={{ mt: 4 }}
              variant="contained"
            >
              <Typography sx={{ margin: "5px 20px" }}>查询</Typography>
            </LoadingButton>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}

function AudioPlayer(props: { url: string }) {
  const [state, setState] = React.useState({
    start: false
  });

  const play = (e: React.MouseEvent<HTMLButtonElement>) => {
    setState({ start: true })
  }

  return (state.start ?
    <Box maxWidth="350px">
      <audio controls src={props.url} autoPlay></audio>
    </Box>
    :
    <IconButton onClick={play}>
      <PlayCircleIcon />
    </IconButton>)
}

type record = {
  index: number,
  createTime: string,
  userId: string,
  sessionId: string,
  lessonId: string,
  finished: boolean,
  logUrl?: string,
  duplexAudioUrl?: string,
  duplexAudioLength?: string,
  studentAudioUrl?: string,
  studentAudioLength?: string,
}

function RecordTable(props: { rows: record[] }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>Create Time</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Session ID</TableCell>
            <TableCell>Lesson ID</TableCell>
            <TableCell>Is Finished</TableCell>
            <TableCell>Log</TableCell>
            <TableCell>Duplex Audio</TableCell>
            <TableCell>Duplex Audio Length</TableCell>
            <TableCell>Student Audio</TableCell>
            <TableCell>Student Audio Length</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow key={row.index}>
              <TableCell>{row.index}</TableCell>
              <TableCell>{row.createTime}</TableCell>
              <TableCell>{row.userId}</TableCell>
              <TableCell>{row.sessionId}</TableCell>
              <TableCell>{row.lessonId}</TableCell>
              <TableCell>{row.finished ? "True" : "False"}</TableCell>
              <TableCell>
                <IconButton href={"/log/" + row.sessionId}>
                  <ViewListIcon />
                </IconButton>
                <IconButton href={row.logUrl ?? ''}>
                  <DownloadIcon />
                </IconButton>
              </TableCell>
              <TableCell>
                {row.duplexAudioUrl == null ? null :
                  <React.Fragment>
                    {<AudioPlayer url={row.duplexAudioUrl}></AudioPlayer>}
                    <IconButton href={row.duplexAudioUrl ?? ''}>
                      <DownloadIcon />
                    </IconButton>
                  </React.Fragment>
                }
              </TableCell>
              <TableCell>{row.duplexAudioUrl == null ? null : row.duplexAudioLength}</TableCell>
              <TableCell>
                {row.studentAudioUrl == null ? null :
                  <React.Fragment>
                    {<AudioPlayer url={row.studentAudioUrl}></AudioPlayer>}
                    <IconButton href={row.studentAudioUrl ?? ''}>
                      <DownloadIcon />
                    </IconButton>
                  </React.Fragment>
                }
              </TableCell>
              <TableCell>{row.studentAudioUrl == null ? null : row.studentAudioLength}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


type HomePageState = {
  rows: record[],
  page: number,
  pageCount: number
}

function HomePage() {
  const [state, setState] = useState(null as unknown as HomePageState)
  const mergeSetState = (object: any) => {
    setState((preState) => ({ ...preState, ...object }))
  }

  const navigate = useNavigate();
  let location = useLocation();



  const padStart = (num: number, width: number) => {
    let str = num.toString()
    while (str.length < width) str = "0" + str;
    return str
  }

  const onClickQuery = (param: QueryParameters) => {
    let queryParams: string[] = []
    if (param.sessionId != null) queryParams.push('session_id=' + param.sessionId)
    if (param.lessonId != null) queryParams.push('lesson_id=' + param.lessonId)
    if (param.userId != null) {
      let users = param.userId.split(',')
      users.forEach((e) => {
        e = e.trim()
        if (e.length !== 0) queryParams.push('user_id=' + e)
      })
    }
    if (param.dateRange[0] != null && param.dateRange[1] != null) queryParams.push('date_range=' + param.dateRange[0]?.format('MM/DD/YYYY') + '-' + param.dateRange[1]?.format('MM/DD/YYYY'))
    if (queryParams.length === 0) return

    navigate("/query?" + queryParams.join('&'))
  }

  const normalizeParams = (queryString: string) => {
    if (queryString.length === 0) return queryString

    let params = location.search.substring(1).split('&')
    let index = params.findIndex((v) => v.startsWith('page'))
    if (index === -1) {
      params.push('page=1')
    }
    params.sort()
    return '?' + params.join('&')
  }

  var queryString = normalizeParams(location.search)
  let [response] = useQuery(queryString)
  var responseData = remoteValue(response)
  useEffect(() => {
    if (queryString.length !== 0 && response.state === 'success') {
      const sizeToDuration = (length: number) => {
        let durationSec = length / (2 * 16000)
        let min = padStart(Math.floor(durationSec / 60), 2)
        let sec = padStart(length % 60, 2)
        return `${min}:${sec}`
      }

      var data = responseData
      let records = data.records as any[];
      let rows: record[] = []
      records?.forEach((v, i) => {
        rows.push({
          index: i + 1 + (data.current_page - 1) * 30,
          createTime: v.create_time,
          userId: v.user_id,
          sessionId: v.session_id,
          lessonId: v.lesson_id,
          finished: v.is_finished,
          logUrl: v.log?.S3Key,
          duplexAudioUrl: v.duplex?.S3Key,
          duplexAudioLength: sizeToDuration(v.duplex?.Size ?? 0),
          studentAudioUrl: v.student?.S3Key,
          studentAudioLength: sizeToDuration(v.student?.Size ?? 0)
        });
      });
      mergeSetState({ rows: rows, page: data.current_page, pageCount: data.page_count });
    }
  }, [queryString.length, response.state, responseData])

  const body = useMemo(() => {
    if (response.state === 'loading') {
      return <Box component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}>
        <Container maxWidth="lg" sx={{ mt: '50vh', ml: '45vw' }}>
          <CircularProgress />
        </Container>
      </Box>
    } else if (state == null) {
      return <Box component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}>
        <Container maxWidth="lg" sx={{ mt: '50vh', ml: '40vw' }}>
          <Typography variant="h6" component="div" >
            点击右上角🔍进行查询
          </Typography>
        </Container>
      </Box>
    } else {
      const onChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        let pair = location.search.substring(1).split('&')
        let index = pair.findIndex((v) => v.startsWith('page'))
        if (index === -1) {
          pair.push('page=' + value)
        } else {
          pair[index] = 'page=' + value
        }
        navigate("/query?" + pair.join('&'))
      }

      return <Box component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '90vh',
          overflow: 'auto',
          pt: 10,
          paddingX: 2
        }}>
        <RecordTable rows={state.rows} />
        <Box sx={{ margin: "10px" }}>
          <Pagination
            count={state.pageCount}
            color="primary"
            page={state.page}
            onChange={onChangePage} />
        </Box >
      </Box>
    }
  }, [state, response, location.search, navigate])

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="absolute">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Alix Study Records
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <QueryFormDrawer onQuery={onClickQuery} />
        </Toolbar>
      </AppBar>
      {body}
    </Box>
  );
}

export default HomePage
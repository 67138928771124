import {CSSProperties, FC, PropsWithChildren, ReactElement, useMemo} from "react";
import {useParams} from "react-router-dom";
import {log, useLog} from "./globalStates";
import {RenderSuspense} from "./states/concurrent";
import {ForceRenderTransition, getChild, ops, Render, setTree, Subject, subject, union, useSubject} from "./states";
import {Button, List, ListItem, useEventCallback} from "@mui/material";
import ReactJson from "react-json-view";
import _ from 'lodash';

const logFilterKeys = ["ASR", "DM", "REPLY", "INTERRUPT", "RTM", "Relay", 'Bot Utterance', "Customized", "Other"] as const
const columnKeys = ["line_number", "timestamp", "location", "type"]
type logFilter = Partial<Record<(typeof logFilterKeys | typeof columnKeys)[number], boolean>>
const cell = {padding: 4, borderStyle: 'none none none ridge'}
const styles: Record<string, CSSProperties> = {
  lineNumber: {display: 'inline-block', width: 40, ...cell},
  timestamp: {display: 'inline-block', width: 100, ...cell},
  location: {display: 'inline-block', width: 270, overflow: "auto", ...cell},
  type: {display: 'inline-block', width: 10, ...cell}
}
export const LogList: FC<{ sessionID: string }> = ({sessionID}) => {
  const filter = useMemo(() => subject<logFilter>({
    "ASR": true,
    "DM": true,
    "INTERRUPT": true,
    "RTM": true,
    'Bot Utterance': true,
    "line_number": true,
    "timestamp": true
  }), [])
  return <ForceRenderTransition>
    <div style={{display: 'flex', flexDirection: 'column', height: '100%', padding: 8, boxSizing: 'border-box'}}>
      <div>{
        logFilterKeys.map(c => <Render key={c}>{function FilterButton() {
          const [selected, setSelected] = useSubject(getChild(filter, c))
          return <Button
            size='small'
            color='warning'
            variant={selected ? 'contained' : 'outlined'}
            sx={{opacity: selected ? 1 : 0.5, whiteSpace: 'nowrap', marginRight: 1, marginBottom: 1}}
            onClick={useEventCallback(() => setSelected(!selected))}>{c}</Button>
        }}</Render>)
      }
        <Render>{function FilterButton() {
          const [f] = useSubject(filter)
          const selected = logFilterKeys.every(s => f[s])
          return <Button
            size='small'
            color='warning'
            variant={selected ? 'contained' : 'outlined'}
            sx={{opacity: selected ? 1 : 0.5, whiteSpace: 'nowrap', marginRight: 1, marginBottom: 1}}
            onClick={useEventCallback(() => setTree(filter, {...f, ..._.fromPairs(logFilterKeys.map(c => [c, !selected]))}))}>All</Button>
        }}</Render>
        {columnKeys.map(c => <Render key={c}>{function FilterButton() {
          const [selected, setSelected] = useSubject(getChild(filter, c))
          return <Button
            size='small'
            variant={selected ? 'contained' : 'outlined'}
            sx={{opacity: selected ? 1 : 0.5, whiteSpace: 'nowrap', marginRight: 1, marginBottom: 1}}
            onClick={useEventCallback(() => setSelected(!selected))}>{c}</Button>
        }}</Render>)}
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        background: '#757575',
        color: 'white',
        paddingTop: 4,
        paddingBottom: 4
      }}>
        <Visible when={getChild(filter, 'line_number')}>
          <span style={styles.lineNumber}>line</span>
        </Visible>
        <Visible when={getChild(filter, 'timestamp')}>
          <span style={styles.timestamp}>time</span>
        </Visible>
        <Visible when={getChild(filter, 'location')}>
          <span style={styles.location}>location</span>
        </Visible>
        <Visible when={getChild(filter, 'type')}>
          <span style={styles.type}>type</span>
        </Visible>
        <div style={{display: 'inline-block', flex: 1, ...cell}}>
          content
        </div>
      </div>
      <div style={{height: 0, flex: '1 1 100%', overflow: 'auto'}}>
        <RenderSuspense>{function LogList() {
          const {logs} = useLog.getOrSuspend(sessionID)
          return <List>{logs.map((log, index) =>
            <ListItem disableGutters
                      key={index}
                      sx={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        margin: 0,
                      }}>
              <Render>{function LogItem() {
                const {type, element} = union.identity(useMemo(() => event(log), []))
                const content = useMemo(() => {
                  switch (true) {
                    case !!element:
                      return element
                    case log.log_type === 'E':
                      return <span style={{color: 'red'}}>{log.log_content}</span>
                    default:
                      return <span>{log.log_content}</span>
                  }
                }, [element])
                const [visible] = useMemo(() => ops(filter).thru(_ => _.map(f =>
                  !logFilterKeys.some(c => f[c]) || logFilterKeys.some(c => f[c] && type === c
                  ))), [type]).use()
                return <div style={{
                  background: type === 'DM' ? '#e1e1e1' : undefined,
                  visibility: visible ? 'visible' : 'collapse',
                  height: visible ? undefined : 0,
                  flexDirection: 'row',
                  alignItems: 'stretch',
                  display: 'flex',
                  width: '100%',
                  borderStyle: visible ? 'none none ridge none' : 'none',
                }}>
                  <Visible when={getChild(filter, 'line_number')}>
                    <span style={{...styles.lineNumber, ...cell}}>{index + 1}</span>
                  </Visible>
                  <Visible when={getChild(filter, 'timestamp')}>
              <span style={{
                ...styles.timestamp, ...cell
              }}>{log.log_timestamp.replace(/\d{3,}/g, (m) => m.substring(0, 2))}</span>
                  </Visible>
                  <Visible when={getChild(filter, 'location')}>
                    <span style={{...styles.location, ...cell,}}>{log.location}</span>
                  </Visible>
                  <Visible when={getChild(filter, 'type')}>
                    <span style={{...styles.type, ...cell,}}>{log.log_type}</span>
                  </Visible>
                  <div style={{
                    display: 'inline-block',
                    flex: 1,
                    overflow: 'auto',
                    ...cell,
                    borderStyle: 'none ridge none ridge'
                  }}>
                    {content}
                  </div>
                </div>
              }}</Render>
            </ListItem>)}</List>
        }}</RenderSuspense></div>
    </div>
  </ForceRenderTransition>
}

const Visible: FC<PropsWithChildren<{ when: Subject<boolean | undefined> }>> = ({children, when}) => {
  const [v] = useSubject(when)
  if (!v) return <></>
  return <>{children}</>
}

function event(l: log): { type?: log['event_type'], element?: ReactElement } {
  switch (true) {
    case l.event_type === 'ASR': {
      const msg = JSON.parse(l.event_content)
      return {
        type: l.event_type,
        element: <ReactJson style={{fontSize: 16, color: 'black'}} src={msg} name={`user: ${msg.transcript}`}
                            collapsed/>
      }
    }
    case l.event_type === 'DM':
      return {
        type: l.event_type,
        element: <ReactJson style={{fontSize: 16}} src={JSON.parse(l.event_content)} name='DM Turn End' collapsed/>
      }
    case !!l.event_type:
      return {
        type: l.event_type,
        element: <ReactJson style={{fontSize: 16}} src={JSON.parse(l.event_content)} name={l.event_type} collapsed/>
      }
    case l.log_content.startsWith("Send RTM message:"): {
      const msg = JSON.parse(l.log_content.substring("Send RTM message:".length))
      return {
        type: 'RTM',
        element: <ReactJson style={{fontSize: 16}} src={msg}
                            name={msg['action'] === 'custom' ? msg['type'] : msg['action'] || 'RTM'} collapsed/>
      }
    }
    case l.log_content.startsWith("Will relay RTM messages:"):
      return {
        type: 'Relay',
        element: <ReactJson style={{fontSize: 16}}
                            src={JSON.parse(l.log_content.substring("Will relay RTM messages:".length))} name="Relay"
                            collapsed/>
      }
    case l.log_content.includes("Bot message customized"):
      return {type: 'Customized'}
    case /Bot message \(\d+\):/.test(l.log_content):
      return {
        type: 'Bot Utterance',
        element: <>{l.log_content.replace(/\[O: \d+] Bot message \((\d+)\): /, 'bot: ')}</>
      }
    case l.log_content.includes('will synthesize'):
      return {
        type: 'Bot Utterance',
        element: <>{l.log_content.replace(/\[R:\d+] will synthesize \(([^()]*)\)/, 'bot: $1')}</>
      }
  }
  return {type: "Other"}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const sessionID = useParams<{ session_id: string }>().session_id
  if (!sessionID) return <>missing session id</>
  return <LogList sessionID={sessionID}/>
}

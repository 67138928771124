import _ from 'lodash'
import { identityMemoizedLoader } from "./states";
import axios from "axios";

export const isDev = _.once(() => window.location.host.includes('localhost') || window.location.host.includes('dev'))
export const logServerURL = _.once(() => isDev() ? 'bot-records-dev.lingverse.co' : 'bot-records.lingverse.co')
export type logResponse = {
  logs: {
    audio_time_stamp: string
    log_type: string
    event_type: string
    event_content: string
    date: string
    log_timestamp: string
    location: string
    log_content: string
  }[]
}
export type log = logResponse['logs'][number]
export const useLog = identityMemoizedLoader<string, logResponse>(async (sessionId) => (await axios.get(`https://${logServerURL()}/api/v1/log?session_id=${sessionId}`)).data)
export const useQuery = identityMemoizedLoader<string, any>(async (query) =>
  query.length === 0 ? null : (await axios.get(`https://${logServerURL()}/api/v1/query${query}`)).data
)

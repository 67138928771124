import {FC, Suspense, useEffect, useState} from "react";
import {useMounted} from "./components";
import {Render} from "./thunk";
import _ from 'lodash'

export function usePromise<T>(promise: Promise<T>): T {
  const [mounted] = useMounted()
  const [state, setState] = useState<Suspendable<T>>({state: 'loading', promise})
  useEffect(() => {
    setState({state: 'loading', promise})
    ;(async () => {
      try {
        const data = await promise
        if (mounted.value) {
          setState({state: 'success', data})
        }
      } catch (error: any) {
        if (mounted.value) {
          setState({state: 'failure', error})
        }
      }
    })()
    return () => void 0
  }, [mounted, promise])
  switch (state.state) {
    case 'loading':
      throw state.promise
    case 'failure':
      throw state.error
    case 'success':
      return state.data
  }
}

type Suspendable<T> =
  | { state: 'loading', promise: Promise<T> }
  | { state: 'success', data: T }
  | { state: 'failure', error: Error }

export const RenderSuspense:
  FC<(
    & Omit<Parameters<typeof Suspense>[0], 'children'>
    & Pick<Parameters<typeof Render>[0], 'children'>
    )> = (props) =>
  <Suspense {..._.omit(props, 'children')}>
    <Render>{props.children}</Render>
  </Suspense>

import _ from 'lodash'
import {incompatible, Subject} from './index'

export type Remote<T> =
  typeof loading
  | { state: 'failure'; message: string; error?: Error }
  | { state: 'success'; data: T }
export type RemotePaged<T> =
  Exclude<Remote<T>, { state: 'success' }>
  | { state: 'loadingMore', data: T }
  | { state: 'success'; data: T; failure?: string }
export type RemoteValue<R extends Remote<any>> = R extends Remote<infer v> ? v : incompatible
export type remoteValues<t extends object> = t extends Remote<any>[] ? remoteArrayValues<t> : remoteObjectValues<t>

export type RefreshPair<T> = [Remote<T>, () => Promise<T | undefined>]

export const union: {
  get: <T, K extends string & (T extends infer P ? keyof P : never)>(
    t: T,
    k: K,
  ) => T extends { [_ in K]: infer P } ? P | undefined : never
  identity: <T>(t: T) => (T extends any ? (_: Partial<T>) => void : never) extends (_: infer U) => void ? U : never
} = _

export function refreshRemoteValues(...v: RefreshPair<any>[]): Promise<any> {
  const p: Promise<any>[] = []
  for (const [value, refresh] of v) {
    if (value.state === 'failure') p.push(refresh())
  }
  return Promise.all(p)
}

export function untilSuccessOrFailure<T, R extends Extract<Remote<T>, { state: 'success' | 'failure' }> | { state: Exclude<string, 'success' | 'failure'>, [_: string]: any }>(subject: Subject<R>): Promise<T> {
  return new Promise((resolve, reject) => {
    if (apply(subject.value)) return
    const subscription = subject.didSet(r => {
      if (apply(r)) subscription()
    })

    function apply(r: R): boolean {
      switch (r.state) {
        case 'success':
          resolve(r.data)
          return true
        case 'failure':
          reject(new Error(r.message))
          return true
        default:
          return false
      }
    }
  })
}

export type remoteArrayValues<t extends Remote<any>[]> = t extends [
    // optimization case, allows the compiler to go four times deeper
    Remote<infer a>,
    Remote<infer b>,
    Remote<infer c>,
    Remote<infer d>,
    ...infer tail
  ]
  ? [a, b, c, d, ...remoteValues<tail>]
  : // two base cases
  t extends [Remote<infer a>, ...infer tail]
    ? [a, ...remoteValues<tail>]
    : []
export type remoteObjectValues<t extends object> = {
  [p in keyof t]: t[p] extends Remote<infer a> ? a : incompatible
}
export type mergedRemotes<t extends Remote<any>[] | { [_: string]: Remote<any> }> = t extends Remote<any>[]
  ? Remote<remoteValues<t>>
  : never

export function mergeRemotes<t extends Remote<any>[] | { [_: string]: Remote<any> }>(rs: t): mergedRemotes<t>
export function mergeRemotes(rs: object): Remote<any> {
  if (Array.isArray(rs)) {
    const data: any[] = []
    for (const r of rs) {
      switch (r.state) {
        case 'loading':
        case 'failure':
          return r
        case 'success':
          data.push(r.data)
          break
      }
    }
    return {state: 'success', data}
  }
  const data: any = {}
  for (const [key, value] of _.toPairs(rs)) {
    const state = _.get(value, 'state')
    if (typeof state !== 'string') continue
    switch (state) {
      case 'loading':
      case 'failure':
        return value
      case 'success':
        data[key] = value.data
        break
    }
  }
  return {state: 'success', data}
}

export const loading = {state: 'loading' as const}

export function remoteValue<T>(r: Remote<T>): T | undefined
export function remoteValue<T>(r: RemotePaged<T>): T | undefined
export function remoteValue<T>(r: RemotePaged<T>): T | undefined {
  switch (r.state) {
    case 'success':
      return r.data
  }
}

export function getOrSuspend<T>(r: Subject<Remote<T>>): T {
  switch (r.value.state) {
    case 'success':
      return r.value.data
    case 'failure':
      throw (r.value as any).error || new Error(r.value.message)
    case 'loading':
      throw untilSuccessOrFailure(r)
  }
}

export function mapRemote<A, B>(a: Remote<A>, f: (_: A) => B): Remote<B> {
  return a.state === 'success' ? {...a, data: f(a.data)} : a
}

export function tuple<T extends any[]>(...elements: T) {
  return elements
}

import {FCC, incompatible} from '.'
import React, {ReactElement} from 'react'
import _ from 'lodash'

type ThunkGroup = (() => any)[] | { [_: string]: () => any }
export type ThunkValues<S extends object> = S extends [() => infer head, ...infer tail]
  ? [head, ...ThunkValues<tail>]
  : { [p in keyof S]: S[p] extends () => infer v ? v : incompatible }

export function evaluateThunks<S extends ThunkGroup>(s: S): ThunkValues<S>
export function evaluateThunks<S extends object>(s: S): any {
  if (Array.isArray(s)) return s.map((ss) => ss())
  return _.mapValues(s, (ss: () => any) => ss())
}

export function RenderThunks<S extends ThunkGroup>({
                                                     thunks,
                                                     children,
                                                   }: {
  thunks: S
  children: (_: ThunkValues<S>) => ReactElement
}) {
  return children(evaluateThunks(thunks))
}

export const Render: (_: { children: () => ReactElement }) => ReactElement = ({children}) => children()

export const Managed: (s: () => void) => FCC = s => ({children}) => {
  s()
  return <>{children}</>
}

export function RenderThunk<S>({thunk, children}: {
  thunk: () => S
  children: (_: S) => ReactElement
}) {
  return children(thunk())
}

export function renderThunks<S extends ThunkGroup>(s: S, f: (_: ThunkValues<S>) => ReactElement) {
  return <RenderThunks<S> thunks={s}>{f}</RenderThunks>
}

export function renderThunk<S>(s: () => S, f: (_: S) => ReactElement) {
  return <RenderThunk thunk={s}>{f}</RenderThunk>
}

export function render(c: () => ReactElement) {
  return <Render>{c}</Render>
}

import _ from 'lodash'
import React, {createContext, useCallback, useContext, useMemo} from 'react'
import {FCC, getOrSuspend, Inherited, loading, Remote, RemotePaged, Subject, subject, useSubject, variable} from '.'

export type LoaderFunc<T> = () => [Remote<T>, (_: T) => void, () => Promise<T | undefined>]
export type Loader<T> = LoaderFunc<T> &
  Inherited & {
  store: React.Context<Subject<Remote<T>> | undefined>
}
export type KeyedLoader<K, V> = ((k: K) => ReturnType<LoaderFunc<V>>) &
  Inherited & {
  store: React.Context<((k: K) => Subject<Remote<V>>) | undefined>
  useRefresh(): (q: K) => Promise<V | undefined>
  getOrSuspend(k: K, ops?: (k: K, s: Subject<Remote<V>>) => Subject<Remote<V>>): V
}

export type Paged<T, N = string> = {
  value: T[],
  nextToken?: N
}

export type PagedLoader<Q, R> = {
  initial(_: Q): Promise<R>
  loadMore(q: Q, t: R): (() => Promise<R>) | undefined
}

export function pagedLoader<Q, R, N = string>(f: (q: Q, nextToken?: N) => Promise<Paged<R, N>>): PagedLoader<Q, Paged<R, N>> {
  return {
    initial: f,
    loadMore(q: Q, t: Paged<R, N>): (() => Promise<Paged<R, N>>) | undefined {
      if (!t.nextToken) return
      return async () => {
        const {value, nextToken} = await f(q, t.nextToken)
        return {
          value: [...t.value, ...value],
          nextToken
        }
      }
    }
  }
}

export type KeyedPagedLoader<Q, V> = ((k: Q) => {
  value: RemotePaged<V>,
  set: (_: V) => void,
  refresh: () => Promise<V | undefined>,
  loadMore: (() => Promise<V | undefined>) | undefined
}) & Inherited & {
  store: React.Context<((q: Q) => Subject<RemotePaged<V>>) | undefined>
  useRefresh(): (q: Q) => Promise<V | undefined>
  useLoadMore(): (q: Q) => (() => Promise<V | undefined>) | undefined
}

export function memoizedPagedLoader<Q, K, R>(
  key: (_: Q) => K,
  loader: PagedLoader<Q, R>,
  useSideEffect: () => (q: Q, r: R) => void = () => () => {
  },
  retries: number = 3,
): KeyedPagedLoader<Q, R> {
  const store = createContext<((q: Q) => Subject<RemotePaged<R>>) | undefined>(undefined)
  const refreshContext = createContext<((q: Q) => Promise<R | undefined>) | undefined>(undefined)
  const loadMoreContext = createContext<((q: Q) => (() => Promise<R | undefined>) | undefined) | undefined>(undefined)

  const doLoad = (q: Q) => retry(retries, () => loader.initial(q))

  const useRefresh = (): ((q: Q) => Promise<R | undefined>) => {
    const refresher = useContext(refreshContext)
    if (!refresher) throw new Error('memoized loader is not in the ancestry tree')
    return refresher
  }

  const useLoadMore = () => function useHook(q: Q): ((() => Promise<R | undefined>) | undefined) {
    const doLoadMore = useContext(loadMoreContext)
    if (!doLoadMore) throw new Error('memoized loader is not in the ancestry tree')
    return doLoadMore(q)
  }

  const Use = (q: Q): {
    value: RemotePaged<R>,
    set: (_: R) => void,
    refresh: () => Promise<R | undefined>,
    loadMore: (() => Promise<R | undefined>) | undefined
  } => {
    const get = useContext(store)
    const refresher = useContext(refreshContext)
    const doLoadMore = useContext(loadMoreContext)
    if (!get || !refresher || !doLoadMore) throw new Error('memoized loader does not exist on the ancestry tree')
    const subject = get(q)
    const [value, set] = useSubject(subject)
    const setRemote = useCallback((data: R) => set({state: 'success', data}), [set])
    const refresh = useCallback(() => refresher(q), [refresher, q])
    const loadMore = useMemo(() => doLoadMore(q), [doLoadMore, q])
    return {value, set: setRemote, refresh, loadMore}
  }


  const Component: FCC = ({children}) => {
    const map = useMemo(() => new Map<K, Subject<RemotePaged<R>>>(), [])
    const sideEffect = useSideEffect()
    const get = useCallback<(q: Q) => Subject<RemotePaged<R>>>(q => {
      const k = key(q)
      let existing = map.get(k)
      if (existing) return existing
      const sub = subject<RemotePaged<R>>(loading)
      const unsubscribe = sub.willGet((curr) => {
        unsubscribe()
        doLoad(q)
          .then((data) => {
            if (sub.value === curr) {
              sub.value = {state: 'success', data}
              sideEffect(q, data)
            }
          })
          .catch((e) => {
            console.error('handled error', e)
            if (sub.value === curr) sub.value = {state: 'failure', message: e.message || JSON.stringify(e)}
          })
      })
      map.set(k, sub)
      return sub
    }, [map, sideEffect])
    const refresh = useCallback(async (q: Q) => {
      const subject = get(q)
      let curr = subject.value
      if (curr.state !== 'loading') curr = subject.value = loading
      return retry(retries, () => doLoad(q))
        .then((data) => {
          if (subject.value === curr) {
            subject.value = {state: 'success', data}
            sideEffect(q, data)
          }
          return data
        })
        .catch((e: any) => {
          if (subject.value === loading) subject.value = {
            state: 'failure',
            message: e.message || JSON.stringify(e)
          }
          return undefined
        })

    }, [get, sideEffect])
    const doLoadMore = useCallback((q: Q) => {
      const subject = get(q)
      const value = subject.value
      if (value.state !== 'success') return
      const load = loader.loadMore(q, value.data)
      if (!load) return
      return async () => {
        const curr = subject.value = {state: 'loadingMore', data: value.data}
        return retry(retries, load)
          .then((data) => {
            if (subject.value === curr) {
              subject.value = {state: 'success', data}
              sideEffect(q, data)
            }
            return data
          })
          .catch((e: any) => {
            console.error(e)
            if (subject.value === curr) subject.value = {
              state: 'success',
              data: curr.data,
              failure: e.message || JSON.stringify(e)
            }
            return undefined
          })
      }
    }, [get, sideEffect])
    return <refreshContext.Provider value={refresh}>
      <loadMoreContext.Provider value={doLoadMore}>
        <store.Provider value={get}>
          {children}
        </store.Provider>
      </loadMoreContext.Provider>
    </refreshContext.Provider>
  }
  return Object.assign(Use, {component: Component, store, useRefresh, useLoadMore})
}

export function identityMemoizedPagedLoader<K, V>(
  loader: PagedLoader<K, V>,
  useSideEffect: () => (k: K, v: V) => void = () => () => {
  },
  retries: number = 3,
): KeyedPagedLoader<K, V> {
  return memoizedPagedLoader(_.identity, loader, useSideEffect, retries)
}


export function memoizedLoader<Q, K, R>(
  key: (_: Q) => K,
  load: (_: Q) => Promise<R>,
  useSideEffect: () => (q: Q, r: R) => void = () => () => {
  },
  retries: number = 3,
): KeyedLoader<Q, R> {
  const doLoad = (q: Q) => retry(retries, () => load(q))
  const store = createContext<((q: Q) => Subject<Remote<R>>) | undefined>(undefined)
  const refreshContext = createContext<((q: Q) => Promise<R | undefined>) | undefined>(undefined)
  const doRefresh = async (
    subject: Subject<Remote<R>>,
    q: Q,
    sideEffect: ReturnType<typeof useSideEffect>,
  ): Promise<R | undefined> => {
    let curr = subject.value
    if (curr.state !== 'loading') curr = subject.value = loading
    return retry(retries, () => doLoad(q))
      .then((data) => {
        if (subject.value === curr) {
          subject.value = {state: 'success', data}
          sideEffect(q, data)
        }
        return data
      })
      .catch((e: any) => {
        if (subject.value === loading) subject.value = {
          state: 'failure',
          message: e.message || JSON.stringify(e)
        }
        return undefined
      })
  }
  const useRefresh = (): ((q: Q) => Promise<R | undefined>) => {
    const refresher = useContext(refreshContext)
    if (!refresher) throw new Error('memoized loader is not in the ancestry tree')
    return refresher
  }
  const Use = (q: Q): [Remote<R>, (_: R) => void, () => Promise<R | undefined>] => {
    const get = useContext(store)
    const refresher = useContext(refreshContext)
    if (!get || !refresher) throw new Error('memoized loader does not exist on the ancestry tree')
    const subject = get(q)
    const [value, set] = useSubject(subject)
    const setRemote = useCallback((data: R) => set({state: 'success', data}), [set])
    const refresh = useCallback(() => refresher(q), [refresher, q])
    return [value, setRemote, refresh]
  }
  const Component: FCC = ({children}) => {
    const map = useMemo(() => new Map<K, Subject<Remote<R>>>(), [])
    const sideEffect = useSideEffect()
    const get = useCallback((q: Q) => {
      const k: K = key(q)
      let existing = map.get(k)
      if (existing) return existing
      const sub = subject<Remote<R>>(loading)
      const unsubscribe = sub.willGet((curr) => {
        unsubscribe()
        doLoad(q)
          .then((data) => {
            if (sub.value === curr) {
              sub.value = {state: 'success', data}
              sideEffect(q, data)
            }
          })
          .catch((e) => {
            console.error('handled error', e)
            if (sub.value === curr) sub.value = {state: 'failure', message: e.message || JSON.stringify(e)}
          })
      })
      map.set(k, sub)
      return sub
    }, [map, sideEffect])
    const refresh = useCallback(async (q: Q) => doRefresh(get(q), q, sideEffect), [get, sideEffect])

    return (
      <refreshContext.Provider value={refresh}>
        <store.Provider value={get}>{children}</store.Provider>
      </refreshContext.Provider>
    )
  }

  const useGetOrSuspend: (q: Q, ops?: (q: Q, s: Subject<Remote<R>>) => Subject<Remote<R>>) => R = (q, ops = (q, s) => s) => {
    const get = useContext(store)
    if (!get) throw new Error('memoized loader does not exist on the ancestry tree')
    return getOrSuspend(ops(q, get(q)))
  }
  return Object.assign(Use, {component: Component, store, useRefresh, getOrSuspend: useGetOrSuspend})
}

export function identityMemoizedLoader<K, V>(
  load: (_: K) => Promise<V>,
  useSideEffect: () => (q: K, r: V) => void = () => () => {
  },
  retries: number = 3,
): KeyedLoader<K, V> {
  return memoizedLoader(_.identity, load, useSideEffect, retries)
}

export async function retry<T>(times: number, f: () => Promise<T>): Promise<T> {
  for (let i = 1; ; i++) {
    try {
      return await f()
    } catch (e: any) {
      if (i >= times) throw e
      await new Promise(_.partial(setTimeout, _, 2 ** (i - 1) * 1000))
    }
  }
}

export function loader<T>(
  load: () => Promise<T>,
  {
    useSideEffect = () => () => {
    },
    retries = 1,
  }: {
    useSideEffect?: () => (_: T) => void,
    retries?: number,
  } = {}): Loader<T> {
  const doLoad = async () => await retry(retries, () => load())
  const doRefresh = async (
    subject: Subject<Remote<T>>,
    sideEffect: ReturnType<typeof useSideEffect>,
  ): Promise<T | undefined> => {
    let curr = subject.value
    if (curr.state !== 'loading') curr = subject.value = loading
    return doLoad()
      .then((data) => {
        if (subject.value === curr) {
          subject.value = {state: 'success', data}
          sideEffect(data)
        }
        return data
      })
      .catch((e) => {
        if (subject.value === loading) subject.value = {
          state: 'failure',
          message: e.message || JSON.stringify(e)
        }
        return undefined
      })
  }

  const useVariable = variable<Remote<T>>(loading, () => {
    const sideEffect = useSideEffect()
    return (v) => {
      const sub = subject(v)
      const unsubscribe = sub.willGet((curr) => {
        unsubscribe()
        doLoad()
          .then((data) => {
            if (sub.value === curr) {
              sub.value = {state: 'success', data}
              sideEffect(data)
            }
          })
          .catch((e) => {
            console.error('handled error', e)
            if (sub.value === curr) sub.value = {state: 'failure', message: e.message || JSON.stringify(e)}
          })
      })
      return sub
    }
  })

  const Use = (): [Remote<T>, (_: T) => void, () => Promise<T | undefined>] => {
    const subject = useContext(useVariable.store)
    if (!subject) throw new Error('loader is not in the ancestry tree')
    const [remoteValue, setRemoteValue] = useVariable()
    const setValue = useCallback((data: T) => setRemoteValue({state: 'success', data}), [setRemoteValue])
    const sideEffect = useSideEffect()
    const refresh = useCallback(() => doRefresh(subject, sideEffect), [sideEffect, subject])
    return [remoteValue, setValue, refresh]
  }
  return Object.assign(Use, {component: useVariable.component, store: useVariable.store})
}
